<template>
    <v-row no-gutters>
        <v-col cols="12">
            <ProductBar :organizationId="$route.params.organizationId" :coupon="coupon" class="mb-6" v-if="coupon"></ProductBar>
            <AccessDeniedOverlay v-if="forbiddenError"></AccessDeniedOverlay>
            <v-row justify="center" class="py-5 px-10 mt-2" v-if="coupon">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <p class="text-caption text-end grey--text text--darken-2 mb-0">Coupon {{ coupon.id }}</p>
                    <v-card class="pa-0 mt-0">
                        <v-toolbar dense flat color="green darken-2" dark>
                            <v-toolbar-title>
                                Coupon
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <!-- <v-card-text>
                            <p class="text-caption mb-0 pb-0 mt-0">The following coupon information is PUBLIC and is shown to customers.</p>
                        </v-card-text> -->
                        <v-simple-table dense>
                            <template #default>
                                <tbody>
                                    <tr>
                                        <th style="min-width: 100px;">Label</th>
                                        <td style="width: 100%;">
                                            <EditableText :value="coupon.label" @input="saveCouponLabel" dense/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Description</th>
                                        <td>
                                            <EditableText :value="coupon.description" @input="saveCouponDescription" dense/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Type</th>
                                        <td>
                                            <EditableTextSelect :value="coupon.type" :items="couponTypeChoices" @input="saveCouponType" dense/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Reference</th>
                                        <td>
                                            <EditableText :value="coupon.ref" @input="saveCouponRef" dense/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Active</th>
                                        <td>
                                            <EditableTextSelect :value="coupon.is_active" :items="yesnoChoices" @input="saveCouponIsActive" dense/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Stackable</th>
                                        <td>
                                            <EditableTextSelect :value="coupon.is_stackable" :items="yesnoChoices" @input="saveCouponIsStackable" dense/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Recurring</th>
                                        <td>
                                            <EditableTextSelect :value="coupon.is_recurring" :items="yesnoChoices" @input="saveCouponIsRecurring" dense/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Mode</th>
                                        <td>
                                            <EditableTextSelect :value="coupon.discount_mode" :items="modeChoices" @input="saveCouponDiscountMode" dense/>
                                            <span v-if="saveButtonDisplay && coupon.discount_mode === 'amt'">
                                                This change has not yet been saved. Please enter the discount amount.
                                            </span>
                                            <span v-if="saveButtonDisplay && coupon.discount_mode === 'pct'">
                                                This change has not yet been saved. Please enter the discount percent.
                                            </span>
                                        </td>
                                    </tr>
                                    <tr v-if="coupon.discount_mode === 'amt'">
                                        <th>Flat amount</th>
                                        <td>
                                            <EditableText :value="coupon.discount_amount" @input="saveCouponDiscountAmount" dense></EditableText>
                                        </td>
                                    </tr>
                                    <tr v-if="coupon.discount_mode === 'pct'">
                                        <th>Percentage</th>
                                        <td>
                                            <EditableText :value="coupon.discount_percent" @input="saveCouponDiscountPercent" dense></EditableText>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <!-- <v-card-actions v-if="saveButtonDisplay">
                            <v-btn color="green darken-2 white--text" @click="saveCouponDiscountAmountOrPercentWithMode">Save</v-btn>
                        </v-card-actions> -->
                    </v-card>

                    <v-card class="pa-0 mt-6">
                        <v-toolbar dense flat color="green darken-2" dark>
                            <v-toolbar-title>
                                Coupon Codes
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon :to="{ name: 'organization-create-coupon-code', params: { organizationId: $route.params.organizationId }, query: { couponId: coupon.id } }">
                                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width></font-awesome-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card-text v-if="Array.isArray(couponCodeList)">
                            <p class="mb-0 pb-0" v-if="couponCodeList.length === 0">
                                No coupon codes found
                            </p>
                            <v-simple-table v-if="couponCodeList.length > 0">
                                <template #default>
                                    <thead>
                                        <tr>
                                            <!-- <th>ID</th> -->
                                            <!-- <th>Coupon ID</th> -->
                                            <th>Code</th>
                                            <th>Email</th>
                                            <th>Not before</th>
                                            <th>Not after</th>
                                            <th># times used</th>
                                            <th>Created on</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="couponCode in couponCodeList" :key="couponCode.id" @click="navigateToCouponCode(couponCode.id)">
                                            <!-- <td>
                                                <router-link :to="viewCouponCodeLink(coupon)">{{ couponIdText(coupon.id) }}</router-link>
                                            </td>
                                            <td>
                                                <router-link :to="viewCouponLink(coupon)">{{ couponIdText(coupon.coupon_id) }}</router-link>
                                            </td> -->
                                            <td>
                                                {{ couponCode.code }}
                                            </td>
                                            <td>
                                                <span v-if="couponCode.not_before">{{ formatDate(couponCode.not_before) }}</span>
                                            </td>
                                            <td>
                                                <span v-if="couponCode.not_after">{{ formatDate(couponCode.not_after) }}</span>
                                            </td>
                                            <td>
                                                {{ couponCode.activated }}
                                            </td>
                                            <td>
                                                {{ formatDate(couponCode.created_on) }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>

                    <v-card class="pa-0 mt-6">
                        <v-toolbar dense flat color="green darken-2" dark>
                            <v-toolbar-title>
                                Assigned Coupons <!-- Coupon Emails -->
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon :to="{ name: 'organization-create-coupon-email', params: { organizationId: $route.params.organizationId }, query: { couponId: coupon.id } }">
                                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width></font-awesome-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card-text v-if="Array.isArray(couponEmailList)">
                            <p class="mb-0 pb-0" v-if="couponEmailList.length === 0">
                                No assigned coupons found
                            </p>
                            <v-simple-table v-if="couponEmailList.length > 0">
                                <template #default>
                                    <thead>
                                        <tr>
                                            <!-- <th>ID</th> -->
                                            <!-- <th>Coupon ID</th> -->
                                            <th>Code</th>
                                            <th>Email</th>
                                            <th>Not before</th>
                                            <th>Not after</th>
                                            <th># times used</th>
                                            <th>Created on</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="saveCouponEmail in couponEmailList" :key="saveCouponEmail.id" @click="navigateToCouponEmail(saveCouponEmail.id)">
                                            <!-- <td>
                                                <router-link :to="viewCouponCodeLink(coupon)">{{ couponIdText(coupon.id) }}</router-link>
                                            </td>
                                            <td>
                                                <router-link :to="viewCouponLink(coupon)">{{ couponIdText(coupon.coupon_id) }}</router-link>
                                            </td> -->
                                            <td>
                                                {{ saveCouponEmail.email }}
                                                <!-- TODO: info icon with hover for description? and it would be fully editable in the record view -->
                                            </td>
                                            <td>
                                                <span v-if="saveCouponEmail.not_before">{{ formatDate(saveCouponEmail.not_before) }}</span>
                                            </td>
                                            <td>
                                                <span v-if="saveCouponEmail.not_after">{{ formatDate(saveCouponEmail.not_after) }}</span>
                                            </td>
                                            <td>
                                                {{ saveCouponEmail.activated }}
                                            </td>
                                            <td>
                                                {{ formatDate(saveCouponEmail.created_on) }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>

                    <v-card class="pa-0 mt-6">
                        <v-toolbar dense flat color="green darken-2" dark>
                            <v-toolbar-title>
                                Eligible Products
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <!-- TODO: should be navigating to a select product page which would return user to this url with a product id when selected, OR should open a dialog from which user can select product on this page -->
                            <v-btn icon @click="showProductSelectionDialog">
                                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width></font-awesome-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card-text v-if="Array.isArray(linkCouponProductList)">
                            <p class="mb-0 pb-0" v-if="linkCouponProductList.length === 0">
                                No eligible products
                            </p>
                            <v-simple-table v-if="linkCouponProductList.length > 0">
                                <template #default>
                                    <thead>
                                        <tr>
                                            <!-- <th>ID</th> -->
                                            <!-- <th>Coupon ID</th> -->
                                            <th>Name</th>
                                            <!-- <th>Type</th> -->
                                            <!-- <th>Not before</th>
                                            <th>Not after</th> -->
                                            <!-- <th># times used</th>
                                            <th>Created on</th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="linkCouponProduct in linkCouponProductList" :key="linkCouponProduct.product_id" @click="navigateToProduct(linkCouponProduct.product_id)">
                                            <td>
                                                {{ linkCouponProduct._related_item.product.name }}
                                            </td>
                                            <!-- <td>
                                                <span v-if="couponCode.not_before">{{ formatDate(couponCode.not_before) }}</span>
                                            </td>
                                            <td>
                                                <span v-if="couponCode.not_after">{{ formatDate(couponCode.not_after) }}</span>
                                            </td>
                                            <td>
                                                {{ couponCode.activated }}
                                            </td>
                                            <td>
                                                {{ formatDate(couponCode.created_on) }}
                                            </td> -->
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>
                    <DialogSelectProduct v-model="dialogSelectProductVisible" @selected="addEligibleProduct"></DialogSelectProduct>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
// import draggable from 'vuedraggable';
import EditableText from '@/components/EditableText.vue';
import EditableTextSelect from '@/components/EditableTextSelect.vue';
// import EditableProductPrice from '@/components/EditableProductPrice.vue';
import ProductBar from '@/components/ProductBar.vue';
import AccessDeniedOverlay from '@/components/AccessDeniedOverlay.vue';
// import EditableProductSaasContent from '@/components/EditableProductSaasContent.vue';
// import TextLink from '@/components/TextLink.vue';
import DialogSelectProduct from '@/components/DialogSelectProduct.vue';

export default {
    components: {
        // draggable,
        // TextLink,
        EditableText,
        EditableTextSelect,
        // EditableProductPrice,
        ProductBar,
        AccessDeniedOverlay,
        // EditableProductSaasContent,
        DialogSelectProduct,
    },
    data: () => ({
        organization: null,
        coupon: null,
        couponCodeList: null, // all coupon codes associated with this coupon
        couponEmailList: null, // all coupon emails associated with this coupon
        linkCouponProductList: null, // all eligible products with which user can use this coupon (only if coupon applies to a product or applies to an order for specific products)
        status: null,
        error: null,
        forbiddenError: false,

        dialogEditBrandProfile: false,
        editableBrandProfileAlias: null,
        submitFormTimestamp: null,

        modeChoices: [
            { text: 'Flat Amount', value: 'amt' },
            { text: 'Percentage', value: 'pct' },
            // { text: 'Lowest', value: 'min' },
            // { text: 'Highest', value: 'max' },
            // { text: 'Both', value: 'sum' },
        ],
        saveButtonDisplay: false,
        dialogSelectProductVisible: false,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
            couponTypeChoices: (state) => state.couponTypeChoices,
            // productPublishedChoices: (state) => state.productPublishedChoices,
        }),
        organizationName() {
            return this.organization?.name ?? 'Unknown';
        },
        isViewReady() {
            return this.organization !== null;
        },
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        isEditBrandProfileAliasFormComplete() {
            return this.editableBrandProfileAlias;
        },
        isCreatePriceFormComplete() {
            return false;
        },
        // customerProductLink() {
        //     // TODO: get default site with possible custom hostname...  and the brandprofile, if needd...
        //     return "";http://customer.unicornsprings.test/brand/libertydns/product?id=06J5B62A4Z7WRCCBET4G
        // },
        yesnoChoices() {
            return [
                { text: 'Yes', value: true },
                { text: 'No', value: false },
            ];
        },
    },
    watch: {
        dialogEditBrandProfile(newValue) {
            if (newValue) {
                this.editableBrandProfileAlias = this.brandprofile;
                this.$nextTick(() => {
                    setTimeout(() => { this.$activateInput('editableBrandProfileInput'); }, 1);
                });
            }
        },
    },
    methods: {
        async loadOrganization() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadOrganization: true });
                const response = await this.$client.organization(this.$route.params.organizationId).currentOrganization.get();
                console.log(`organization/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.organization = response;
                } else {
                    // TODO: redirect back to organization list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load organization', err);
                if (err.response?.status === 403) {
                    this.forbiddenError = true;
                }
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadOrganization: false });
            }
        },
        async loadCoupon() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadCoupon: true });
                const query = { id: this.$route.query.id };
                const response = await this.$client.organization(this.$route.params.organizationId).coupon.get(query);
                console.log(`editcoupon.vue: response ${JSON.stringify(response)}`);
                if (response?.item) {
                    this.coupon = response.item;
                } else if (response?.id) {
                    this.coupon = response;
                } else {
                    // TODO: redirect back to organization list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load product', err);
                if (err.response?.status === 403) {
                    this.forbiddenError = true;
                }
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadCoupon: false });
            }
        },
        // async checkCoupon() {
        //     try {
        //         this.error = false;
        //         this.$store.commit('loading', { checkCoupon: true });
        //         const query = { id: this.$route.query.id };
        //         const response = await this.$client.organization(this.$route.params.organizationId).coupon.check(query, { item: 'product_type' }); // check that all linked products are of the same type as the coupon
        //         console.log(`editcoupon.vue: response ${JSON.stringify(response)}`);
        //         if (response) {
        //             this.status = response;
        //         } else {
        //             this.status = null;
        //         }
        //     } catch (err) {
        //         console.error('failed to check product status', err);
        //         this.status = null;
        //         this.error = true;
        //     } finally {
        //         this.$store.commit('loading', { checkCoupon: false });
        //     }
        // },
        async loadCouponCodeList() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadCouponCodeList: true });
                const query = { coupon_id: this.$route.query.id };
                const response = await this.$client.organization(this.$route.params.organizationId).couponCode.search(query);
                console.log(`editcoupon.vue: response ${JSON.stringify(response)}`);
                if (Array.isArray(response.list)) {
                    this.couponCodeList = response.list;
                } else {
                    this.couponCodeList = [];
                }
            } catch (err) {
                console.error('failed to load coupon codes', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadCouponCodeList: false });
            }
        },
        async loadCouponEmailList() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadCouponEmailList: true });
                const query = { coupon_id: this.$route.query.id };
                const response = await this.$client.organization(this.$route.params.organizationId).couponEmail.search(query);
                console.log(`editcoupon.vue: response ${JSON.stringify(response)}`);
                if (Array.isArray(response.list)) {
                    this.couponEmailList = response.list;
                } else {
                    this.couponEmailList = [];
                }
            } catch (err) {
                console.error('failed to load coupon emails', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadCouponEmailList: false });
            }
        },
        async loadLinkCouponProductList() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadLinkCouponProductList: true });
                const query = { include: 'product' };
                const response = await this.$client.organization(this.$route.params.organizationId).linkCouponProduct.search(query);
                console.log(`loadLinkCouponProductList: response ${JSON.stringify(response)}`);
                if (Array.isArray(response.list)) {
                    this.linkCouponProductList = response.list;
                } else {
                    this.linkCouponProductList = [];
                }
            } catch (err) {
                console.error('failed to load coupon codes', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadLinkCouponProductList: false });
            }
        },
        async saveCouponAttr(name, value) {
            try {
                this.error = false;
                this.$store.commit('loading', { saveProductAttr: true });
                const response = await this.$client.organization(this.$route.params.organizationId).coupon.edit({ id: this.$route.query.id }, { [name]: value });
                console.log(`saveProductAttr: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.$set(this.coupon, name, value);
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit coupon' });
                }
            } catch (err) {
                console.error(`failed to edit coupon attr [${name}]: ${JSON.stringify(value)}`, err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit coupon' });
            } finally {
                this.$store.commit('loading', { saveProductAttr: false });
            }
        },
        async saveCouponLabel(value) {
            this.saveCouponAttr('label', value);
        },
        async saveCouponRef(value) {
            this.saveCouponAttr('ref', value);
        },
        // async saveCouponAlias(alias) {
        //     this.saveCouponAttr('alias', alias);
        // },
        async saveCouponType(value) {
            await this.saveCouponAttr('type', value);
            // await this.loadCouponCodeList();
        },
        async saveCouponDescription(value) {
            this.saveCouponAttr('description', value);
        },
        async saveCouponIsActive(value) {
            await this.saveCouponAttr('is_active', value);
        },
        async saveCouponIsStackable(value) {
            await this.saveCouponAttr('is_stackable', value);
        },
        async saveCouponIsRecurring(value) {
            await this.saveCouponAttr('is_recurring', value);
        },
        // TODO: saveCouponDiscount
        async saveCouponDiscountMode(value) {
            this.$set(this.coupon, 'discount_mode', value);
            if (value === 'amt') {
                if (this.coupon.discount_amount) {
                    await this.saveCouponAttr('discount_mode', value);
                } else {
                    this.saveButtonDisplay = true;
                }
            }
            if (value === 'pct') {
                if (this.coupon.discount_percent) {
                    await this.saveCouponAttr('discount_mode', value);
                } else {
                    this.saveButtonDisplay = true;
                }
            }
        },
        async saveCouponDiscountAmount(value) {
            await this.saveCouponAttr('discount_amount', value);
            await this.saveCouponAttr('discount_mode', 'amt');
            this.saveButtonDisplay = false;
        },
        async saveCouponDiscountPercent(value) {
            await this.saveCouponAttr('discount_percent', value);
            await this.saveCouponAttr('discount_mode', 'pct');
            this.saveButtonDisplay = false;
        },
        // TODO: limit per account
        // TODO: limit per user
        // TODO: currency
        // TODO: not before
        // TODO: not after
        formatDate(timestamp) {
            return new Date(timestamp).toLocaleDateString();
        },
        navigateToCouponCode(id) {
            this.$router.push({ name: 'organization-edit-coupon-code', params: { organizationId: this.$route.params.organizationId }, query: { id } });
        },
        showProductSelectionDialog() {
            this.dialogSelectProductVisible = true;
        },
        async addEligibleProduct(product) {
            try {
                this.error = false;
                this.$store.commit('loading', { addEligibleProduct: true });
                const response = await this.$client.organization(this.$route.params.organizationId).linkCouponProduct.create({ coupon_id: this.$route.query.id, product_id: product.id });
                console.log(`addEligibleProduct: response ${JSON.stringify(response)}`);
                if (response?.isCreated) {
                    // this.loadLinkCouponProductList();
                    this.linkCouponProductList.push({ coupon_id: this.$route.query.id, product_id: product.id, _related: { product } });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to add eligible product' });
                }
            } catch (err) {
                console.error(`failed to add eligible product ${JSON.stringify(product)}`, err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to add eligible product' });
            } finally {
                this.$store.commit('loading', { addEligibleProduct: false });
            }
        },
    },
    mounted() {
        this.loadOrganization();
        this.loadCoupon();
        this.loadCouponCodeList();
        this.loadCouponEmailList();
        this.loadLinkCouponProductList();
    },
};
</script>
