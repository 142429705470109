<template>
    <v-dialog :value="value" @input="$emit('input', $event.target ? $event.target.value : false)" max-width="600">
        <v-card class="pa-0" max-width="600">
            <v-toolbar short flat color="white">
                <v-toolbar-title class="green--text">Select product</v-toolbar-title>
            </v-toolbar>
            <v-card-text  class="px-5" v-if="Array.isArray(productList)">
                <p class="mb-0 pb-0" v-if="productList.length === 0">
                    No products found
                </p>
                <v-simple-table v-if="productList.length > 0">
                    <template #default>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="product in productList" :key="product.id" @click="selectProduct(product)">
                                <td>
                                    {{ product.name }}
                                </td>
                                <td>
                                    {{ product.type }}
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <!-- <v-btn elevation="4" class="green white--text" @click="select" :disabled="!isFormComplete">
                    <span>Create</span>
                </v-btn> -->
                <v-btn text color="grey" @click="cancel">
                    <span>Cancel</span>
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    props: ['value'],
    data: () => ({
        productList: null,
    }),
    computed: {
    },
    methods: {
        async loadProductList() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadProductList: true });
                const query = {};
                const response = await this.$client.organization(this.$route.params.organizationId).product.search(query);
                console.log(`dialogselectproduct.vue: response ${JSON.stringify(response)}`);
                if (Array.isArray(response.list)) {
                    this.productList = response.list;
                } else {
                    this.productList = [];
                }
            } catch (err) {
                console.error('failed to load coupon codes', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadProductList: false });
            }
        },
        selectProduct(product) {
            this.$emit('selected', product);
            this.$emit('input', false);
        },
        cancel() {
            this.$emit('input', false);
        },
    },
    mounted() {
        this.loadProductList();
    },
};
</script>
